<template>
  <section class="peno-dashboard">
    <transition mode="out-in" name="fade">
      <router-view class="animated" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'peno-dashboard',

  data() {
    return {
      currentTab: this.$route.name,
    };
  },

  watch: {
    $route(to, from) {
      this.currentTab = to.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
</style>
